<template>
  <div class="shou" @click="back">
      返回
  </div>
</template>

<script>
export default {
  name: 'back',
  data () {
    return {
    }
  },
  methods: {
      back() {
          this.$router.go(-1)
      }
  }
}
</script>

<style scoped lang='less'>
div {
    background-color: #20222a;
    padding: 5px 15px;
    color: #fff;
}
</style>