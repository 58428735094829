import Vue from "vue";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "normalize.css";
import "@/assets/css/index.css";
import storage from "@/utils/index.js";
import timeTo from "@/utils/time.js";
import Back from "@/components/back.vue";
import VueParticles from "vue-particles";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueParticles)

Vue.directive('permissions', function (el, binding) {
  el.style.display = 'none'
})
//导出xls
import VueAMap from "vue-amap"; // 引入高德地图
// 高德地图初始化
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "93df7ef67e61d06a400994a96c5b82ea", // 如果没有这个key请去高德地图开放平台申请
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.Geolocation",
    "AMap.Geocoder",
    "AMap.AMapManager",
    "AMap.Marker",
  ],
  v: "1.4.4",
});
Vue.component("back", Back);
Vue.prototype.$storage = storage;
Vue.prototype.$timeTo = timeTo;
//导入echarts柱状图
import echarts from 'echarts'
import { importManager } from "less";
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;

Vue.filter("datefilter2", function(time) {
  return timeTo.time6(time);
});
Vue.filter("datefilter", function(time) {
  return timeTo.time3(time);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
