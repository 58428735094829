import Vue from 'vue'
import { Loading, Alert, Aside, Button, Card, Cascader, Checkbox, Col, Container, DatePicker, Dialog, Dropdown, DropdownItem, DropdownMenu, Form, FormItem, Header, Image, Input, Main, Message, Option, Pagination, Radio, Row, Select, Table, TableColumn, TabPane, Tabs, Tag, TimePicker, Tooltip, Upload, Collapse, CollapseItem, Link, Switch, Tree, Steps, Step, MenuItem, Submenu, MenuItemGroup, Menu, TimeSelect, Transfer, Progress, ButtonGroup,Drawer,CheckboxGroup} from 'element-ui'
import { options } from 'less'


Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Tooltip)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(FormItem)
Vue.use(Option)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Card)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(Tag)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Dialog)
Vue.use(TimePicker)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tooltip)
Vue.use(Link)
Vue.use(Switch)
Vue.use(Tree)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(TimeSelect)
Vue.use(Transfer)
Vue.use(Progress)
Vue.use(ButtonGroup)
Vue.use(Drawer)
Vue.use(CheckboxGroup)

import { MessageBox } from 'element-ui';
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading;
Vue.prototype.$confirm = MessageBox.confirm;
export const message = Vue.prototype.$message;