import timeFormat from './time'
const storage = {
    set: function (key, data) {
      return window.localStorage.setItem(key, window.JSON.stringify(data))
    },
    get: function (key) {
      try {
        return window.JSON.parse(window.localStorage.getItem(key))
      } catch (e) {
        this.remove(key)
      }
    },
    remove: function (key) {
      return window.localStorage.removeItem(key)
    }
  }

  export function filenameFormat(filename) {
    let notime = filename.split('=')[1]
    let nameStr = notime.substr(10)
    let timeStr = notime.substr(0, 10)
    console.log(timeFormat.time3(timeStr))
    return timeFormat.time3(timeStr) + ' ' + decodeURIComponent(nameStr)
  }
export default storage