<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
/* #app {
} */
.shuaxin{width: 100%; background: #f1f3f4; margin-bottom: 10px; box-sizing: border-box; padding: 10px;}

</style>
