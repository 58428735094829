import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter)

const routes2 = [

]

const routes = [
	{ path: '/up', name: 'up', component: () => import('@/components/up') },
// { path: '/fuwenben', name: 'fuwenben', component: () => import('@/views/fuwenben') },
  { path: '/login', name: 'login', component: () => import('@/views/login') },
  { path: '/', redirect: '/home' , name: 'index', component: () => import('@/views/index'), children: [
    { path: '/shop', redirect: '/shop/list', name: 'shop', component: () => import('@/views/shop/index'), children: [
      {path: '/shop/list', meta: { keepAlive: true }, name: 'shoplist', component: () => import('@/views/shop/shoplist')},
      {path: '/shop/role', meta: { keepAlive: false }, name: 'shoprole', component: () => import('@/views/shop/shoprole')},
      {path: '/shop/user', meta: { keepAlive: false }, name: 'userlist', component: () => import('@/views/shop/userlist')},
      {path: '/shop/shopAdd', meta: { keepAlive: false }, name: 'shopAdd', component: () => import('@/views/shop/shopAdd')},
      {path: '/shop/detail', meta: { keepAlive: false }, name: 'shopdetail', component: () => import('@/views/shop/detail')},
      {path: '/shop/type', meta: { keepAlive: false }, name: 'shoptype', component: () => import('@/views/shop/shoptype')},
      {path: '/shop/shopcomplaint', meta: { keepAlive: false }, name: 'shopcomplaint', component: () => import('@/views/shop/shopcomplaint')},
      {path: '/shop/components/shopcomplaintModel', name: 'shopcomplaintModel', component: () => import('@/views/shop/components/shopcomplaintModel')},
    ]},
    { path: '/home', name: 'home', component: () => import('@/views/home/index')},

    // 系统设置
	  { path: '/getSystemsetting', name: 'getSystemsetting', component: () => import('@/views/getSystemsetting/getSystemsetting')},

    // 投诉管理
	  // { path: '/complaintList', name: 'complaintList', component: () => import('@/views/complaintList/complaintList')},

    // 商品管理
    { path: '/goods', redirect: '/goods/list', name: 'goods', component: () => import('@/views/goods/index'), children: [
      { path: '/goods/list', name: 'goodsList', component: () => import('@/views/goods/goodsList')},
      { path: '/goods/classify', name: 'classify', component: () => import('@/views/goods/classify/goodsclassify')},
	    { path: '/goods/add', name: 'add', component: () => import('@/views/goods/addGoods')},
      { path: '/goods/classify/detail', name: 'classifydetail', component: () => import('@/views/goods/classify/classifyDetail')},
      { path: '/goods/virtualUser', name: 'virtualUser', component: () => import('@/views/goods/virtualUser')}
    ]},

    // 订单管理
    { path: '/order', redirect: '/order/list', name: 'order', component: () => import('@/views/order/index'), children: [
      { path: '/order/list', name: 'orderList', component: () => import('@/views/order/orderList')},
      { path: '/order/detail', name: 'orderdetail', component: () => import('@/views/order/detail')},
      { path: '/order/after', name: 'orderAfter', component: () => import('@/views/order/orderAfter')},
      { path: '/order/warning', name: 'orderwarning', component: () => import('@/views/order/warning')}
    ]},

    // 财务管理
    { path: '/finance', redirect: '/finance/shop/list', name: 'finance', component: () => import('@/views/finance/index'), children: [
      {path: '/finance/shop/list', name: 'shopfinanceList', component: () => import('@/views/finance/shopList')},
      {path: '/finance/user/list', name: 'userfinanceList', component: () => import('@/views/finance/userlist')},
      {path: '/finance/income', name: 'income', component: () => import('@/views/finance/income')},
      {path: '/finance/storeDeposit', name: 'storeDeposit', component: () => import('@/views/finance/storeDeposit')},
    ]},
    // { path: '/user', redirect: '/user/list', name: 'user', component: () => import('@/views/user/index'), children: [
    //   { path: '/user/list', name: 'userlist', component: () => import('@/views/user/userlist')}
    // ]},

    { path: '/jurisdiction', redirect: '/jurisdiction/admin', name: 'jurisdiction', component: () => import('@/views/jurisdiction/index'), children: [
      { path: '/jurisdiction/admin', name: 'jurisdictionadmin', component: () => import('@/views/jurisdiction/adminlist')},
      { path: '/jurisdiction/role', name: 'userlist', component: () => import('@/views/jurisdiction/rolelist')},
      { path: '/jurisdiction/oplog', name: 'opterationlog', component: () => import('@/views/jurisdiction/oplog')}
    ]},

	  // 评论列表
    { path: '/comment', redirect: '/get_comment_list', name: 'comment', component: () => import('@/views/comment/index'),children:[
      { path: '/get_comment_list', name: 'get_comment_list', component: () => import('@/views/comment/get_comment_list')},
    ]},

	  // 资讯列表
    { path: '/news', redirect: '/newsManage/list', name: 'news', component: () => import('@/views/newsManage/index'),children:[
      { path: '/newsManage/list', name: 'newsManagelist', component: () => import('@/views/newsManage/list')},
      { path: '/newsManage/cate', name: 'newsManagecate', component: () => import('@/views/newsManage/cate')},
      { path: '/newsManage/addnews', name: 'addnews', component: () => import('@/views/newsManage/addnews')},
      { path: '/newsManage/manageTalk', name: 'manageTalk', component: () => import('@/views/newsManage/manageTalk')},
      { path: '/newsManage/newsBanner', name: 'newsBanner', component: () => import('@/views/newsManage/newsBanner')},
    ]},

    // 课程
    { path: '/Courses', redirect: '/Courses/list', name: 'Courses', component: () => import('@/views/Courses/index'),children:[
      { path: '/Courses/list', name: 'newsManagelist', component: () => import('@/views/Courses/list')},
      { path: '/Courses/cate', name: 'newsManagecate', component: () => import('@/views/Courses/cate')},
      { path: '/Courses/addnews', name: 'addnews', component: () => import('@/views/Courses/addnews')},
      { path: '/Courses/add_addnews', name: 'add_addnews', component: () => import('@/views/Courses/add_addnews')},
      { path: '/Courses/addnewslist', name: 'addnewslist', component: () => import('@/views/Courses/addnewslist')},
      { path: '/Courses/chaptersave', name: 'chaptersave', component: () => import('@/views/Courses/chaptersave')},
      { path: '/Courses/add_chaptersave', name: 'add_chaptersave', component: () => import('@/views/Courses/add_chaptersave')},
    ]},

    // 菜单管理
    { path: '/menu', redirect: '/menu/list', name: 'menuIndex', component: () => import('@/views/menus/index'), children: [
      {path: '/menu/list', name: 'menulist', component: () => import('@/views/menus/menulist')}
    ]},
    // 直播管理
    { path: '/live', redirect: '/live/room', name: 'live', component: () => import('@/views/live/index'), children: [
      {path: '/live/room', name: 'liveroom', component: () => import('@/views/live/room')},
	    {path: '/live/live_list', name: 'live_list', component: () => import('@/views/live/live_list')},
      {path: '/live/drawSet', name: 'drawSet', component: () => import('@/views/live/drawSet')},
      {path: '/live/winningPersonnel', name: 'winningPersonnel', component: () => import('@/views/live/winningPersonnel')},
      {path: '/live/cate', name: 'livecate', component: () => import('@/views/live/cate')},
      {path: '/live/addliveroom', name: 'addliveroom', component: () => import('@/views/live/addliveroom')},
      {path: '/live/live_goods', name: 'livegoods', component: () => import('@/views/live/live_goods')},
      {path: '/live/inviteRanking', name: 'inviteRanking', component: () => import('@/views/live/inviteRanking')},
      {path: '/live/commodityRanking', name: 'commodityRanking', component: () => import('@/views/live/commodityRanking')},
      {path: '/live/closeInviteRanking', name: 'closeInviteRanking', component: () => import('@/views/live/closeInviteRanking')},
      {path: '/live/luckyBag', name: 'luckyBag', component: () => import('@/views/live/luckyBag')},
      {path: '/live/addLuckyBag', name: 'addLuckyBag', component: () => import('@/views/live/addLuckyBag')},
      {path: '/live/luckyBagInfo', name: 'luckyBagInfo', component: () => import('@/views/live/luckyBagInfo')},
      {path: '/live/winnerList', name: 'winnerList', component: () => import('@/views/live/winnerList')},
    ]},

    // 营销活动
    { path: '/activity', redirect: '/activity/couponlist', name: 'activity', component: () => import('@/views/activity/index'), children: [
	    // {path: '/activity/liveLottery', name: 'liveLottery', component: () => import('@/views/activity/liveLottery')},
      {path: '/activity/sudoku', name: 'sudoku', component: () => import('@/views/activity/sudoku')},
      {path: '/activity/couponlist', name: 'coupon', component: () => import('@/views/activity/couponlist')},
      {path: '/activity/lotterywinList', name: 'lotterywinList', component: () => import('@/views/activity/lotterywinList')},
	    {path: '/activity/OhSnap', name: 'OhSnap', component: () => import('@/views/activity/OhSnap')},
      {path: '/activity/secondsKillGoods', name: 'secondsKillGoods', component: () => import('@/views/activity/secondsKillGoods')},
    ]},

    // 物流
    { path: '/logistics', redirect: '/logistics/logisticsTemplate', name: 'logisticsTemplate', component: () => import('@/views/logistics/index'), children: [
      {path: '/logistics/logisticsTemplate', name: 'logisticsTemplate', component: () => import('@/views/logistics/logisticsTemplate')},
      {path: '/logistics/viewUsage', name: 'viewUsage', component: () => import('@/views/logistics/viewUsage')},
      {path: '/logistics/addLogisticsTemplate', name: 'addLogisticsTemplate', component: () => import('@/views/logistics/addLogisticsTemplate')}
    ]},
  ]}
]


const router = new VueRouter({
  routes
})

// 前置守卫判断用户是否登录
router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem('admin_token');
  console.log(token);
    if(token == null) {
        if(to.path === '/login') {
            next()
        } else {
            next({path: '/login'})
        }
    } else {
      console.log(123);
        next();
    }

});


export default router
